nav {
  background: #1e1f59;
  position: fixed;
  top: 0;
}

.nav-link {
  color: white;
  text-decoration: none
}

#title {
  top: 30%;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  text-align: center
}

#subtitle {
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  text-align: center
}

img {
  width: 100%
}

#FacebookLogo {
  height: 25vh;
  width: auto;
}

#IndeedLogo {
  height: 25vh;
  width: auto;
}

svg {
  display: inline-block;
  height: 25vh;
  width: 25vh;
  color: white;
  fill: white;
}

#titleCard {
  background: linear-gradient( rgba(0, 0, 0, 0.2),
     rgba(0, 0, 0, 0.2) );
}

ul {
  list-style-type: none;
}

#locations-list {
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
}

li:hover {
  background-color: #b8b8b8;
}

#locations-list::-webkit-scrollbar-thumb {
  border-radius: 5%;
}

#locations-list::-webkit-scrollbar-track {
  background-color: white;
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}